<template>
    <div class="wrapper">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
            class="goods-list"
        >
            <template v-if="empty">
                <v-empty type="order" />
            </template>
            <div class="main" v-for="(it, index) in list" @click="$router.push({path:'/my/orderListSMCS/refundDetails/details',query: {id: it.orderSn,orderItemId:it.orderItemId,orderId: it.id,}})" :key="index">
                <div class="tkxi flex jc-sb">
                    <div class="flex ai-c">
                        <img class="is" src="/img/icon-shop.png" alt="" />
                        <span class="yun">i团团云供应链</span>
                    </div>
                    <div v-if="it.status == 0" class="zhong">售后中</div>
                    <div v-if="it.status == 1" class="zhong">售后中</div>
                    <div v-if="it.status == 2" class="zhong">售后中</div>
                    <div v-if="it.status == 3" class="zhong">已退款</div>
                    <div v-if="it.status == 4" class="zhong">售后失败</div>
                    <div v-if="it.status == 5" class="zhong">撤销</div>
                    <div class="bxian"></div>
                </div>
                <div class="flex jc-sb">
                    <div class="lf">
                        <img :src="it.goodsPic" alt="" />
                    </div>
                    <div class="ri flex fd-c jc-sb">
                        <div class="ellipsis">
                            <div class="sz">{{ it.goodsName }}</div>
                            <span class="sz sza">{{ it.goodsAttr }}</span>
                        </div>
                        <div class="tkq">退款：¥{{ it.returnAmount }}</div>
                    </div>
                </div>
                <div class="tkclose" v-if="it.status == 5">
                    退款已关闭
                </div>
                <div class="mbtb flex jc-fd" v-if="it.status == 0||it.status == 1">
                    <div class="btn" @click.stop="getReturnApplyCancel(it)">取消售后</div>
                    <div class="btn sq">查看详情</div>
                </div>
            </div>
        </van-list>
        
        <v-loveGoodsList />
        <div class="kong"></div>
        <div class="go-home flex ai-c jc-c">
            <div class="btn" @click="$router.push('/home')">返回商城</div>
        </div>
    </div>
</template>

<script>
import loveGoodsList from "@/components/loveGoodsList";
import { getReturnApplyPage, getReturnApplyCancel } from "../../../api";
export default {
    components: {
        "v-loveGoodsList": loveGoodsList,
    },
    data() {
        return {
            current: 1,
            list: [],
            loading: false,
            finished: false,
            empty: true
        };
    },
    created() {
    },
    methods: {
        onLoad() {
          this.getReturnApplyPage()
        },
        getReturnApplyPage() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true
            })
            getReturnApplyPage({
                current: this.current,
                size: 20,
            }).then((res) => {
                var list = res.data.data.records;
                this.current++
                this.loading = false;
                if (list.length==0) {
                  this.finished = true;
                }
                this.list = this.list.concat(list)
                if (this.list.length==0) {
                    this.empty = true
                } else {
                    this.empty = false
                }
            }).finally(()=>{
              this.$toast.clear()
            });
        },
        getReturnApplyCancel(item) {
          this.$dialog.confirm({
            title: '提示',
            message: '确认撤销申请吗？',
          })
            .then(() => {
              // on confirm
              this.$toast.loading({
                duration: 0,
                forbidClick: true
              })
              getReturnApplyCancel(item.orderItemId).then(res=>{
                this.$toast.success('撤销成功')
                item.status = 5
                // this.$root.eventHub.$emit('send')
              }).catch(()=>{
                this.$toast.clear()
              })
            })
            .catch(() => {
              // on cancel
            })
          
        }
    },
};
</script>

<style scoped>
.go-home {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.wrapper>>>.van-loading{
  display: none;
}
.go-home .btn {
    width: 6.9rem;
    height: 0.9rem;
    background: #f35e3a;
    border-radius: 0.45rem;
    font-size: 0.36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 0.9rem;
}
.kong {
    width: 100%;
    height: 2rem;
}
.main .tkclose {
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    width: 100%;
    height: 0.9rem;
    line-height: 0.9rem;
    background: #f6f5f5;
    border-radius: 0.14rem;
    margin-bottom: 0.3rem;
    padding-left: 0.6rem;
    box-sizing: border-box;
}
.bxian {
    width: calc(100% + 0.6rem);
    height: 1px;
    background: #f4f4f4;
    position: absolute;
    bottom: 0;
    left: -0.3rem;
}
.main .yun {
    font-size: 0.26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #61626a;
}
.main .tkxi {
    margin-bottom: 0.5rem;
    position: relative;
    padding-bottom: 0.2rem;
}
.main .zhong {
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f35e3a;
}
.main .is {
    width: 0.22rem;
    height: 0.2rem;
    margin-right: 0.1rem;
}
.main .tkq {
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.wrapper {
}
.main .btn {
    width: 1.7rem;
    height: 0.7rem;
    line-height: 0.7rem;
    text-align: center;
    background: #fdfeff;
    border: 0.02rem solid #61626a;
    border-radius: 0.35rem;
    margin-right: 0.27rem;
    font-size: 0.28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626a;
}
.main .wzjc {
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
}

.main .sq {
    color: #f35e3a;
    border: 0.02rem solid #f35e3a;
    margin-right: 0;
}
.main .copy {
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: rgba(243, 94, 58, 1);
}
.main .xh {
    width: calc(100% - 1.76rem);
    text-align: left;
    font-size: 0.26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626a;
}
.main .yi {
    width: 1.76rem;
    text-align: left;
    font-size: 0.26rem;
    font-family: PingFang;
    font-weight: 500;
    color: #c9c9c9;
}
.main .my {
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626a;
}
.main .sz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626a;
}
.main .sza {
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #aaaaaa;
    background: #f4f4f4;
}
.main .mya {
    font-size: 0.24rem;
    color: #aaaaaa;
}
.main .ri {
    width: calc(100% - 1.76rem);
    height: 1.54rem;
}
.main .lf {
    width: 1.54rem;
    height: 1.54rem;
    background: #f4f4f4;
    border: 1px solid #eeeeee;
    border-radius: 0.01rem;
    margin-bottom: 0.5rem;
}
.main .lf img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main {
    background-color: #fff;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    padding-right: 0.3rem;
    padding-bottom: 0.4rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
    /* position: relative; */
}
</style>
